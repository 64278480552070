import { template as template_eb72139c2f864445b5812ddf4740abe2 } from "@ember/template-compiler";
import NotificationsTracking from "discourse/components/notifications-tracking";
const TagNotificationsTracking = template_eb72139c2f864445b5812ddf4740abe2(`
  <NotificationsTracking
    @onChange={{@onChange}}
    @levelId={{@levelId}}
    @showCaret={{false}}
    @showFullTitle={{false}}
    @prefix="tagging.notifications"
    class="tag-notifications-tracking"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default TagNotificationsTracking;
