import { template as template_6fb6cf084b744dd5ae0549dbe3226a90 } from "@ember/template-compiler";
const FKControlMenuContainer = template_6fb6cf084b744dd5ae0549dbe3226a90(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
