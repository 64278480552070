import { template as template_eab6ab5fb2384908a67d208d5fff8a8f } from "@ember/template-compiler";
const FKLabel = template_eab6ab5fb2384908a67d208d5fff8a8f(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
